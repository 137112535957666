@import "../../styles/text.mixins";
@import "../../styles/devices.mixins";

.hire {
  &-inner {
    min-width: 100%;
  }

  &-title {
    max-width: 576px;
    margin-bottom: 100px;

    @include just-small-desktop {
      margin-bottom: 78px;
    }

    @include tablet-width {
      max-width: none;
    }
  }

  &-content {
    display: flex;
    justify-content: space-between;

    @include tablet-width {
      flex-direction: column;
    }
  }

  &-form {
    flex: 0 1 576px;
    margin-right: 10px;

    @include just-small-desktop {
      flex: 0 1 416px;
    }

    @include tablet-width {
      flex-basis: auto;
    }
  }

   &-locations {
     flex: 0 1 438px;
     margin-left: 10px;
     display: flex;
     flex-direction: column;
     justify-content: space-between;

     @include tablet-width {
       flex-basis: auto;
     }
   }


  &-locations-title {
    @include h2-desktop;

    @include small-desktop {
      @include h2-desktop-small
    }

    @include tablet-width {
      margin: 145px 0 64px;
    }

    @include mobile-width {
      @include h2-mobile;
      margin: 80px 0 46px;
    }
  }

  &-contacts {
    @include tablet-width {
      display: flex;
      justify-content: space-between;
    }

    @include smallTablet-width {
      flex-direction: column;
    }
  }

  &-contact {
    flex: 1;

    .contacts-email {
      margin-bottom: 0;
    }
  }
  &-contact:first-of-type {
    margin-bottom: 64px;

    @include just-small-desktop {
      margin-bottom: 48px;
    }
  }
}