@import '../../styles/main';

.contacts {
  address{
    font-style: normal;
  }

  &-inner {
    width: 100%
  }

  &-title.title {
    @include tablet-width {
      max-width: 550px;
    }

    @media screen and (max-width: 560px){
      font-size: 62px;
    }

    @include mobile-width {
      @include h1-mobile;
    }
  }

  &-locations {
    @include flex;
    margin-top: 56px;

    @include small-desktop {
      margin-top: 63px;
    }

    @include tablet-width {
      margin-top: 56px;
      flex-direction: column;
    }

    @include mobile-width {
      margin-top: 50px;
    }
  }

  &-location {
    @include h5-desktop;
    flex: 0 1 556px;


    @include just-small-desktop {
      @include h5-desktop-small;
      flex-basis: 416px;
    }

    @include tablet-width {
      @include h5-tablet;
      min-width: 100%;

      &:first-of-type {
        margin-bottom: 48px;
      }
    }

    @include mobile-width {
      @include h5-mobile;
      &:first-of-type {
        margin-bottom: 44px;
      }
    }
  }

  &-address {
    @include flex;
    justify-content: flex-start;
    margin-bottom: 16px;

    & > p {
      @include h4-desktop;
      margin-right: 16px;

      @include just-small-desktop {
        @include h4-desktop-small;
      }

      @include tablet-width {
        @include h4-tablet;
      }

      @include mobile-width {
        @include h4-mobile;
      }
    }


  }

  &-email {
    display: inline-block;
  }

  &-map {
    width: 100%;
    height: 400px;
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    margin-top: 32px;

    &__ukr {
      background-image: url('../../assets/locationUkr.jpg')
    }

    &__usa {
      background-image: url('../../assets/locationUSA.jpg')
    }

    @include small-desktop {
      margin-top: 24px;
    }

    @include tablet-width {
      height: 650px;
      margin-top: 32px;
    }

    @include mobile-width {
      height: 420px;
      margin-top: 24px;
    }

    &__pin {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) scale(.7);
      -webkit-animation: pin-scale .8s linear;
      animation: pin-scale .8s linear;

      &:hover {
        -webkit-animation: pin-scale-back .4s linear forwards;
        animation: pin-scale-back .4s linear forwards;
      }
    }
  }
}

@keyframes pin-scale {
  0% {transform: translate(-50%, -50%) scale(1);}
  20%, 50%, 80%, 100% {transform: translate(-50%, -50%) scale(.7);}
  40% {transform: translate(-50%, -50%) scale(.9);}
  60% {transform: translate(-50%, -50%) scale(.8);}
}

@keyframes pin-scale-back {
  0% {transform: translate(-50%, -50%) scale(.7);}
  10% {transform: translate(-50%, -90%) scale(1.1);}
  40%, 100% {transform: translate(-50%, -85%) scale(1);}
  60% {transform: translate(-50%, -75%) scale(.9);}
}

