@import "../../styles/main";

.header {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  background: $white;
  z-index: 9999;

  &-inner {
    @include flex;
    max-width: 1570px;
    padding: 39px 77px;
    margin: 0 auto;

    @include small-desktop {
      max-width: 1132px;
      padding: 29px 50px;
    }

    @include tablet-width {
      padding: 24px 44px;
    }

    @include mobile-width {
      padding: 12px 16px;
    }
  }

  .nav {
    flex-basis: 577px;

    @include small-desktop {
      flex-basis: 416px;
    }

    .toggle-btn {
      display: none;
      position: relative;
      z-index: 999;
      width: 64px;
      height: 32px;
      margin-left: auto;

      @include tablet-width {
        display: block;
      }

      @include mobile-width {
        width: 40px;
        height: 20px;
      }

      & > .toggle-bar {
        position: absolute;
        left: 0;
        background: $black;
        height: 3px;
        width: 100%;
        transition: opacity ease-in-out 0.6s;
        z-index: 999;

        @include mobile-width {
          height: 2px;
        }

        &:nth-child(1) {
          top: 0;
        }

        &:nth-child(2) {
          width: 48px;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);

          @include mobile-width {
            width: 30px;
          }
        }

        &:nth-child(3) {
          bottom: 0;
        }


      }
    }

    .menu {
      @include flex;
      width: 100%;

      @include tablet-width {
        height: 100%;
        position: fixed;
        right: 0;
        top: 0;
        width: 100%;
        z-index: 99;
        overflow-y: auto;
        transform: translateX(100%);
        transition: all ease-in-out 0.6s;
        flex-direction: column;
        justify-content: center;
        background-color: $white;
      }
    }

    &.toggled {
      .menu {
        transform: translateX(0%);
      }

      .toggle-btn > .toggle-bar {
        background: $grey;

        &:nth-child(1) {
          -webkit-animation: bar-sliding-down ease-in-out 600ms forwards normal;
          animation: bar-sliding-down ease-in-out 600ms forwards normal;
        }

        &:nth-child(2) {
          opacity: 0;
        }

        &:nth-child(3) {
          -webkit-animation: bar-sliding-up ease-in-out 600ms forwards normal;
          animation: bar-sliding-up ease-in-out 600ms forwards normal;
        }
      }
    }

    &.toggled-off {
      .toggle-btn > .toggle-bar {
        &:nth-child(1) {
          -webkit-animation: bar-sliding-down-reverse ease-in-out 600ms forwards reverse;
          animation: bar-sliding-down-reverse ease-in-out 600ms forwards reverse;
        }
        &:nth-child(3) {
          -webkit-animation: bar-sliding-up-reverse ease-in-out 600ms forwards reverse;
          animation: bar-sliding-up-reverse ease-in-out 600ms forwards reverse;
        }
      }
    }
  }

  .logo {
    z-index: 999;
    cursor: pointer;

    &-link {
      @include flex;
      width: 169px;
      height: 50px;

      @include tablet-width {
        width: 216px;
        height: 64px;
      }

      @include mobile-width {
        width: 133px;
        height: 40px;
      }

      & > img:first-of-type {
        height: 100%;
        width: auto;
        margin-left: 5px;
      }

      & > img:last-of-type {
        width: 100%;
        height: auto;
      }
    }
  }
}


@keyframes bar-sliding-down {
  0% {
    top: 0;
  }
  50% {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  100% {
    top: 50%;
    left: 50%;
    transform: rotate(45deg) translate(-25px, 22px);
  }
}

@keyframes bar-sliding-down-reverse {
  0% {
    top: 0;
  }
  50% {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  100% {
    top: 50%;
    left: 50%;
    transform: rotate(45deg) translate(-25px, 22px);
  }
}

@keyframes bar-sliding-up {
  0% {
    bottom: 0;
  }
  50% {
    bottom: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  100% {
    top: 50%;
    left: 50%;
    transform: rotate(-45deg) translate(-22px, -25px);
  }
}

@keyframes bar-sliding-up-reverse {
  0% {
    bottom: 0;
  }
  50% {
    bottom: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  100% {
    top: 50%;
    left: 50%;
    transform: rotate(-45deg) translate(-22px, -25px);
  }
}