@import "../../styles/main";

.footer {
  &-inner {
    @include flex;
    @include h6-desktop;
    max-width: 1570px;
    padding: 48px 77px;
    margin: 0 auto;

    @include small-desktop {
      @include h6-desktop-small;
      max-width: 1132px;
      padding: 36px 50px;
    }

    @include tablet-width {
      @include h6-tablet;
      padding: 40px 44px;
    }

    @include mobile-width {
      padding: 32px 16px;
      font-weight: 600;
      font-size: 13px;
      line-height: 13px;
    }

    @media screen and (max-width: 580px) {
      display: flex;
      flex-direction: column;
    }
  }

  &-list {
    display: flex;

    @media screen and (max-width: 580px) {
      margin-bottom: 20px;
    }
  }

  & > .copyrigth span:first-of-type {
    margin-right: 2em;
  }

}

