@import '../../styles/main';

.button {
  @include button-desktop;
  @include center;
  position: relative;
  width: 216px;
  height: 64px;
  border: 2px solid $black;
  background-color: $white;
  overflow-x: clip;
  isolation: isolate;

  @include small-desktop {
    @include button-desktop-small;

    width: 162px;
    height: 48px;
  }

  @include tablet-width {
    @include button-tablet;
    color: $white;
    background: $black;
    width: 336px;
    height: 88px;
  }

  @include mobile-width {
    @include button-mobile;
    width: 100%;
    height: 64px;
  }

  &:before {
    position: absolute;
    content: "";
    top: 0;
    right: 10%;
    width: 20px;
    height: 2px;
    background-color: inherit;
    transform: translateY(-100%);
    opacity: 1;
    transition: opacity linear .5s;

    @include tablet-width {
      display: none;
    }
  }

  &-overlay {
    @include h3-desktop;
    line-height: inherit;
    @include center;
    display: inline-flex;
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    background-color: $black;
    color: $white;
    text-transform: uppercase;

    @include tablet-width {
      display: none;
    }
  }

  &:hover {
    &:before {
      opacity: 0;
    }

    & > .button-overlay  {
      -webkit-animation: bouncing ease-out .5s forwards .5s;
      animation: bouncing ease-out .5s forwards .5s;
    }
  }
}

.button.is-disabled {
  background-color: $grey-table;
  border-color: $grey-table;
  color: $grey;

  .button-overlay {
    display: none;
  }
}

@keyframes bouncing {
  0% {
    width: 0;
  }
  50% {
    width: 150%;
  }
  100% {
    width: 100%;
  }
}

.back-btn {
  @include button-desktop;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 16px 44px;
  cursor: pointer;
  height: 64px;

  @include small-desktop {
    @include button-desktop-small;
  }

  @include tablet-width {
    @include button-tablet;
  }

  @include mobile-width {
    @include button-mobile;
  }

  &-inner {
    width: 128px;
    position: relative;
  }

  &-arrow,
  &-title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: all ease-in-out .6s;
  }


  &-arrow {
    width: 64px;
    height: 32px;
    opacity: 0;
    background-image: url("../../assets/arrow-back.svg");
    background-repeat: no-repeat;
    background-size: cover;
  }

  &:hover {
    .back-btn-arrow {
      opacity: 1;
      left: 0;
      transform: translate(0, -50%);
    }

    .back-btn-title {
      left: 100%;
      transform: translate(-100%, -50%);
    }
  }
}