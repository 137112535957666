@import '../../styles/color';
@import '../../styles/text.mixins';

.career {
  &-title.title {
    @include h1-desktop;

    @include small-desktop {
      @include h1-desktop-small;
    }

    @include tablet-width {
      @include h1-tablet;
    }

    @include smallTablet-width {
      @include h1-desktop;
    }

    @media screen and (max-width: 650px){
      font-size: 72px;
      line-height: 72px;
    }

    @media screen and (max-width: 520px){
      font-size: 56px;
      line-height: 56px;
    }

    @include mobile-width {
      @include h1-mobile;
    }
  }

  &-table {
    @include h5-desktop;
    display: block;
    margin-top: 79px;

    @include small-desktop {
      @include h5-desktop-small;
      margin-top: 48px;
    }

    @include tablet-width {
      @include h5-tablet;
      margin-top: 64px;
    }

    @include mobile-width {
      @include h5-mobile;
      font-weight: 400;
      margin-top: 49px;
    }

    & > thead,
    & > tbody {
      display: block;
    }

    & > thead > tr {
      @include h4-desktop;
      background-color: $black;
      color: $white;
      display: flex;

      @include small-desktop {
        @include h4-desktop-small;
      }
    }

    & > tbody > tr {
      display: flex;
      cursor: pointer;
      align-items: center;
    }

    & > tbody > tr:nth-child(even):not(:first-of-type){
      background-color: $grey-table;
    }

    & > tbody > tr:hover,
    & > tbody > tr:nth-child(even):not(:first-of-type):hover {
      background-color: $black;
      color: $white;
    }

    th,
    td {
      flex: 1;
      padding: 16px 24px;
      text-align: start;
    }
  }

  &-error {
    @include h4-desktop;
    margin: 25px 0;

    @include small-desktop {
      @include h4-desktop-small;
    }
  }
}

.modal {
  position: fixed;
  inset: 0; /* inset sets all 4 values (top right bottom left) much like how we set padding, margin etc., */
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  z-index: 999;


  &-overlay {
    position: absolute;
    inset: 0;
    background-color: rgba(34, 36, 44, 0.4);
    z-index: 9999;
  }

  &-content {
    width: 544px;
    max-width: 90%;
    padding: 56px;
    background-color: $white;
    z-index: 99999;

    position: fixed;
    top: 44%; //according to design modals position is not center, but above
    left: 50%;
    transform: translate(-50%, -50%);

    @include tablet-width {
      width: 720px;
      max-width: 90%;
      padding: 72px;
    }

    @include smallTablet-width {
      padding: 72px 50px;
    }

    @include mobile-width {
      padding: 40px 24px;
    }
  }

  &-title {
    @include h2-desktop;
    text-transform: uppercase;
    text-align: center;

    @include small-desktop {
      @include h2-desktop-small
    }

    @include mobile-width {
      @include h2-mobile;
    }
  }

  &-text {
    margin: 16px 0 64px;
    text-align: center;
  }

  &-form {
    margin: 64px 0 0;
  }

  &-btns-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &.center {
      justify-content: center;
    }

    @include tablet-width {
      flex-direction: column;
    }
  }
}


/*Job-Description*/
.job {

  &-inner {
    min-width: 100%;
  }

  &-header {
    margin-bottom: 72px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    @include small-desktop {
      margin-bottom: 48px;
    }

    @include tablet-width {
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 56px;
    }

    @include mobile-width {
      margin-bottom: 10px;
    }
  }

  &-title.title {
    @include h2-desktop;
    max-width: 696px;
    margin-right: 10px;

    @include small-desktop {
      @include h2-desktop-small
    }

    @include tablet-width {
      margin-bottom: 40px;
    }

    @include mobile-width {
      @include h2-mobile;
      margin-bottom: 47px;
    }
  }

  &-btns-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    @include tablet-width {
      width: 100%;
      justify-content: flex-start;
    }
    @include mobile-width {
      flex-direction: column;
    }
  }

  input[type="file"] {
    display: none;
  }

  &-file-upload {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    text-transform: uppercase;

    font-weight: 800;
    font-size: 18px;
    margin-right: 72px;

    @include small-desktop {
      @include button-desktop-small;
      margin-right: 54px;
    }

    @include smallTablet-width {
      margin-right: 20px;
    }

    &.is-uploaded {
      @include h5-tablet;
      font-weight: 400;

      @include smallTablet-width {
        @include h5-mobile;
      }
    }

    @include mobile-width {
      margin-bottom: 32px;
      align-self: flex-start;
    }

    & > img {
      padding: 2px;
      margin-right: 9px;
    }

    &:hover {
      opacity: .5;
    }
  }

  &-description {
    position: relative;
  }

  &-description-content {
    @include h5-desktop;
    max-height: 400px;
    overflow-y: scroll;
    position: relative;
    padding-bottom: 100px;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      box-shadow: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $black;
    }

    @include small-desktop {
      @include h5-desktop-small;
    }

    @include tablet-width {
      padding-bottom: 0;
      max-height: none;
    }
  }

  &-description-shadow {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.88) 100%);

    @include tablet-width {
      display: none;
    }
  }
}

