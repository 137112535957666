@import '../../styles/main';

.form {
  flex: 0 1 576px;
  min-width: 575px;
  position: relative;

  @include small-desktop {
    flex: 0 1 416px;
    min-width: 416px;
  }

  @include tablet-width {
    flex: 0 0 0;
    min-width: auto;
  }

  .input-wrapper {
    position: relative;
    margin-bottom: 64px;
    cursor: pointer;

    &:last-of-type {
      margin-bottom: 88px;
    }

    @include just-small-desktop {
      margin-bottom: 48px;

      &:last-of-type {
        margin-bottom: 64px;
      }
    }

    & > label {
      @include h6-desktop;
      position: absolute;
      top: 0;
      left: 0;
      cursor: inherit;

      @include small-desktop {
        @include h6-desktop-small;
      }

      @include tablet-width {
        @include h6-tablet;
      }

      @include mobile-width {
        @include h6-mobile;
      }
    }

    & > input {
      @include h5-desktop;
      display: inline-block;
      width: 100%;
      min-height: 35px;
      margin-top: 5px;
      border: 0;
      outline: none;
      padding: 0 0 8px;
      border-bottom: 1px solid $grey-2;
      cursor: inherit;
      transition: all ease-in-out .3s;
      border-radius: 0;

      @include small-desktop {
        @include h5-desktop-small;
      }

      //removing input background-color
      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active{
        -webkit-box-shadow: 0 0 0 30px white inset !important;
      }

      &:focus {
        outline: none;
      }

      &:not(.is-error):focus,
      &:not([value=""]) {
        //margin-top: 32px;
        border-bottom-color: $black;
        -webkit-animation: input-bouncing ease-in-out .5s forwards;
        animation: input-bouncing ease-in-out .5s forwards;

        & ~ label {
          color: $grey
        }
      }

      &.is-error {
        margin-top: 32px;
        border-bottom-color: $red;

        & ~ label {
          color: $grey
        }
      }

      @include tablet-width {
        @include h5-tablet;
      }

      @include mobile-width {
        @include h5-mobile;
      }
    }

    &:hover > input {
      border-bottom-color: $black;
    }
  }

  .error {
    position: absolute;
    bottom: 0%;
    left: 0;
    transform: translateY(100%);
    color: $red;
    font-size: 14px;
    line-height: 14px;
    padding-top: 8px;
  }

  &-error {
    color: $red;
    font-size: 14px;
    line-height: 14px;
    padding-top: 8px;
    margin-bottom: 15px;
  }

  .success-message {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $white;
    display: flex;
    justify-content: center;

    .message {
      &-title {
        @include h2-desktop;
        text-transform: uppercase;
        text-align: center;

        @include small-desktop {
          @include h2-desktop-small
        }

        @include mobile-width {
          @include h2-mobile;
        }
      }

      &-text {
        margin: 16px 0 64px;
        text-align: center;
      }

      &-btn {
        text-align: center;
      }
    }
  }
}


.modal .form {
  min-width: auto;
}

@keyframes input-bouncing {
  0% {
    margin-top: 5px;
  }
  15% {
    margin-top: 1px;
  }
  85% {
    margin-top: 36px;
  }
  100% {
    margin-top: 32px;
  }
}



