@import "devices.mixins";

//to add animation:
// 1.set ref on desirable element
// 2.detect if element is in viewPort by using IntersectionObserver (src directory => helpers directory => index file => useIsInViewport)
// 3.add animation class depends on condition if element is in viewport
//   example: class "appear" set initial styling of elem before animation
//            class "appear-in" (-in at the end) make styling for animation, when elem goes into viewport
//            class "appear-out" (-out at the end) make styling for animation, when elem goes out of viewport

//Home Page: StrengthsSection
.appear {
  transform: translateY(30%);
  -webkit-transform: translate3d(0,0,0);
  opacity: 0;
}

.appear.appear-in {
  -webkit-transform: translateY(0) translate3d(0,0,0);
  transform: translateY(0);
  opacity: 1;
  transition: opacity 1000ms ease-in-out, transform 800ms ease-in-out;
}

.appear.appear-out {
  -webkit-transform: translateY(30%) translate3d(0,0,0);
  transform: translateY(30%);
  opacity: 0;
  transition: opacity 1000ms ease-in-out, transform 800ms ease-in-out;
}


//Home Page: ModelSection
.sliding {
  opacity: 0;
  transition: opacity 1000ms ease-in-out;
}

.sliding.sliding-right {
  -webkit-transform: translateY(-50%) translate3d(0,0,0);
  transform: translateY(-50%);
}

.sliding.sliding-left {
  -webkit-transform: translateY(50%) translate3d(0,0,0);
  transform: translateY(50%);
}

.sliding.sliding-right-in {
  -webkit-transform: translateY(0) translate3d(0,0,0);
  transform: translateY(0);
  opacity: 1;
}

.sliding.sliding-right-out {
  -webkit-transform: translateY(-50%) translate3d(0,0,0);
  transform: translateY(-50%);
  opacity: 0;
}

.sliding.sliding-left-in {
  -webkit-transform: translateY(0) translate3d(0,0,0);
  transform: translateY(0);
  opacity: 1;
}

.sliding.sliding-left-out {
  -webkit-transform: translateY(50%) translate3d(0,0,0);
  transform: translateY(50%);
  opacity: 0;
}

//HomePage: ModelContentBlock
.move-rtl,
.move-ltr {
  opacity: 0;
  transition: all 1000ms ease-in-out;
}

.move-rtl { transform: translateX(-100%); }
.move-ltr { transform: translateX(100%); }

.move-rtl-in,
.move-ltr-in {
  transform: translateX(0%);
  opacity: 1;
}

//HomPage: FiguresSection
//GenesysPage: ResultSection
//list items (figures items moving one by one)
.flying {
  & li {
    -webkit-transform: translateX(160%);
    transform: translateX(160%);
    opacity: 0;
    transition: opacity .8s ease-in-out, transform 1000s ease-in-out;
  }

  &.flying-in li {
    -webkit-animation: ease-in-out forwards;
    animation: ease-in-out forwards;
    opacity: 1;
    transform: translateX(160%);
  }

  //with each item: delay =+ 0.3s;
  &.flying-in li:nth-child(1) {
    animation-name: flying;
    animation-delay: 0s;
    animation-duration: .8s;
  }

  &.flying-in li:nth-child(2) {
    animation-name: flying-2;
    animation-delay: 0.3s;
    animation-duration: 2.1s;
  }

  &.flying-in li:nth-child(3) {
    animation-name: flying-3;
    animation-delay: 0.6s;
    animation-duration: 1.8s;
  }
  &.flying-in li:nth-child(4) {
    animation-name: flying-4;
    animation-delay: 0.9s;
    animation-duration: 1.5s;
  }
  &.flying-in li:nth-child(5) {
    animation-name: flying-5;
    animation-delay: 1.2s;
    animation-duration: 1.2s;
  }
  &.flying-in li:nth-child(6) {
    animation-name: flying-6;
    animation-delay: 1.5s;
    animation-duration: .8s;
  }

  &.flying-out li {
    -webkit-animation: flying-reverse ease-in-out forwards;
    animation: flying-reverse ease-in-out forwards;
    opacity: 1;
    animation-duration: .8s;
  }

  //with each item: delay =+ 0.3s;
  &.flying-out li:nth-child(1) {  animation-delay: 0ms; }
  &.flying-out li:nth-child(2) { animation-delay: 300ms;}
  &.flying-out li:nth-child(3) { animation-delay: 350ms; }
  &.flying-out li:nth-child(4) { animation-delay: 400ms; }
  &.flying-out li:nth-child(5) { animation-delay: 450ms; }
  &.flying-out li:nth-child(6) { animation-delay: 500ms; }
}

@keyframes flying {
  0% {
    -webkit-transform: translateX(160%);
    transform: translateX(160%);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes flying-2 {
  0% {
    -webkit-transform: translateX(160%);
    transform: translateX(160%);
  }
  34% {
    -webkit-transform: translateX(-8%);
    transform: translateX(-8%);
  }
  67% {
    -webkit-transform: translateX(5%);
    transform: translateX(5%);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes flying-3 {
  0% {
    -webkit-transform: translateX(160%);
    transform: translateX(160%);
  }
  34% {
    -webkit-transform: translateX(-11%);
    transform: translateX(-11%);
  }
  67% {
    -webkit-transform: translateX(5%);
    transform: translateX(5%);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes flying-4 {
  0% {
    -webkit-transform: translateX(160%);
    transform: translateX(160%);
  }
  34% {
    -webkit-transform: translateX(-14%);
    transform: translateX(-14%);
  }
  67% {
    -webkit-transform: translateX(5%);
    transform: translateX(5%);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes flying-5 {
  0% {
    -webkit-transform: translateX(160%);
    transform: translateX(160%);
  }
  34% {
    -webkit-transform: translateX(-17%);
    transform: translateX(-17%);
  }
  67% {
    -webkit-transform: translateX(5%);
    transform: translateX(5%);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes flying-6 {
  0% {
    -webkit-transform: translateX(160%);
    transform: translateX(160%);
  }
  34% {
    -webkit-transform: translateX(-20%);
    transform: translateX(-20%);
  }
  67% {
    -webkit-transform: translateX(5%);
    transform: translateX(5%);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

//reverse flying figures
@keyframes flying-reverse {
  0% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
  }
  100% {
    -webkit-transform: translateX(160%);
    transform: translateX(160%);
  }
}

//for Home Page: form
.self-sliding-ltr {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.self-sliding-ltr.self-sliding-ltr-in {
  -webkit-animation: self-sliding-ltr 3000ms ease-in-out forwards;
  animation: self-sliding-ltr 3000ms ease-in-out forwards;
  opacity: 1;
}
.self-sliding-ltr.self-sliding-ltr-out {
  -webkit-animation: self-sliding-ltr-reverse 2000ms ease-in-out forwards reverse;
  animation: self-sliding-ltr-reverse 2000ms ease-in-out forwards reverse;
  opacity: 0;
  transition: opacity 1s .5s ease-in-out;
}

.self-sliding-rtl {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.self-sliding-rtl.self-sliding-rtl-in {
  -webkit-animation: self-sliding-rtl 3000ms ease-in-out forwards;
  animation: self-sliding-rtl 3000ms ease-in-out forwards;
  opacity: 1;
}

.self-sliding-rtl.self-sliding-rtl-out {
  -webkit-animation: self-sliding-rtl-reverse 2000ms ease-in-out forwards reverse;
  animation: self-sliding-rtl-reverse 2000ms ease-in-out forwards reverse;
  opacity: 0;
  transform: translateX(0);
  transition: opacity 1s .5s ease-in-out;
}

@keyframes self-sliding-ltr {
  0% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
  34% {
    -webkit-transform: translateX(8%);
    transform: translateX(8%);
  }
  67% {
    -webkit-transform: translateX(-3%);
    transform: translateX(-3%);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}


@keyframes self-sliding-ltr-reverse {
  0% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
  67% {
    -webkit-transform: translateX(8%);
    transform: translateX(8%);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes self-sliding-rtl {
  0% { transform: translateX(100%);}
  34% { transform: translateX(-8%);}
  67% { transform: translateX(3%);}
  100% { transform: translateX(0); }
}
@keyframes self-sliding-rtl-reverse {
  0% { transform: translateX(100%);}
  67% { transform: translateX(-8%);}
  100% { transform: translateX(0); }
}


//Genesys page: SolutionSection, text block
//See Animated Wrapper component

//Animation "slide-up":
////animation = sliding child component from bottom to top inside animation-wrapper + moving overlay
//1) liner-gradient for animation-wrapper goes with white color from bottom to opacity on top
//2)animated child tag inside animation-wrapper. To animate child need to give cassName="animated" to it
//3) animated child  slides from bottom to top


//starting classes: "slide-up" to add initial style before animation
//for getting animation: add class "slide-up-in" when animation is needed

.slide-up {
  position: relative;
  overflow: hidden;

  &:before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    left: 0;
    z-index: 999;
    opacity: 1;
    transition: height 400ms 800ms ease-in-out;
  }
}

.slide-up:before {
  background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.86) 40.69%, rgba(255, 255, 255, 0.54) 72.34%, rgba(255, 255, 255, 0) 100%);
  bottom: 0;
}

.slide-up-in.slide-up:before {
  height: 0;
  transition: height 400ms 800ms ease-in-out;
}

.slide-up {
  & > .animated-child {
    //transform: translateY(100%);
  }

  &.slide-up-in {
    & > .animated-child {
      transform: translateY(100%);
      animation: sliding-up 2000ms ease-in-out forwards;
    }
  }

  &.slide-up-out {
    & > .animated-child {
      transform: translateY(0);
      animation: sliding-up-reverse 2000ms ease-in-out forwards reverse;
    }
  }
}

@keyframes sliding-up {
  0% {transform: translateY(100%);}
  50% {transform: translateY(-10px);}
  70% {transform: translateY(5%);}
  100% {transform: translateY(0%);}
}

//animation-direction: reverse is set via responsive class
@keyframes sliding-up-reverse {
  0% {transform: translateY(100%);}
  50% {transform: translateY(-10px);}
  70% {transform: translateY(5%);}
  100% {transform: translateY(0%);}
}
