@font-face {
  font-family: 'Metropolis';
  src: local('Metropolis'), local('Metropolis-Regular'), url('../fonts/Metropolis.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Metropolis';
  src: local('Metropolis Medium'), local('Metropolis-Medium'), url('../fonts/Metropolismedium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Metropolis';
  src: local('Metropolis Semi Bold'), local('Metropolis-SemiBold'), url('../fonts/Metropolissemibold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Metropolis';
  src: local('Metropolis Bold'), local('Metropolis-Bold'), url('../fonts/Metropolisbold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Metropolis';
  src: local('Metropolis Extra Bold'), local('Metropolis-ExtraBold'), url('../fonts/Metropolisextrabold.woff2') format('woff2');
  font-weight: 800;
  font-style: normal;
}


