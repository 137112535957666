@import "../../styles/color";
@import "../../styles/general.styles";

.title-overlay,
.title-overlay-partly {
  position: relative;
  overflow: hidden;
  isolation: isolate;

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 50%;
    display: block;
    animation: colorMoving linear 15s infinite;
    z-index: -1;
    filter: blur(10px);
    -webkit-transform: translate3d(0,0,0);
  }

  &.title-overlay--blue::before {
    background: radial-gradient(circle, #53DBF9 0%, #0846E6 20%, #213DAF 30%, #091F77 40%, #22242C 80%, #22242C 100%);
  }

  &.title-overlay--dark-blue::before {
    background: radial-gradient(circle, #7047ed 0%, #0009E5 20%, #311FA3 30%, #311FA3 40%, #1E075F 80%, #1E075F 100%); //#7047ed instead of  #B77FFE  at 0% position
  }

  &.title-overlay--purple::before {
    background: radial-gradient(circle, #B6AAFF 0%, #7708E6 20%, #4F21AF 30%, #460977 40%, #22242C 80%, #22242C 100%);
  }

  &.title-overlay--green::before {
    background: radial-gradient(circle, #41E3ED 0%, #08D8E6 20%, #219EAF 30%, #096377 40%, #22242C 80%, #22242C 100%);
  }

  &.title-overlay--red::before {
    background: radial-gradient(circle, #F9535D 0%, #E6083E 20%, #AF214C 30%, #770930 40%, #22242C 80%, #22242C 100%);
  }

  &.title-overlay--orange::before {
    background: radial-gradient(circle, #FD984F 0%, #EE5424 20%, #CD3505 30%, #5F1903 40%, #22242C 80%, #22242C 100%);
  }

  & > .title,
  & > span {
    mix-blend-mode: screen;
    background-color: $white;
    width: 100%;
    height: 100%;
    word-wrap: break-word;
  }

}

.title-overlay-partly {
  display: inline-block;
  display: -webkit-inline-box; //for prevent span being a litle bit down in accordanse to text, inside which it is
  -webkit-transform: translate3d(0,0,0); //to remove artifacts while moving background
  transform: translate3d(0, 0, 0);

  & > span {
    display: inline-block;
  }
}

@keyframes colorMoving {
  0% {
    top: 50%;
    left: -50%;
  }
  25% {
    top: -50%;
    left: 50%;
  }
  50% {
    top: -50%;
    left: -50%;
  }
  75% {
    top: 50%;
    left: 50%;
  }
  100% {
    top: 50%;
    left: -50%;
  }
}

