@import '../../styles/devices.mixins';
@import '../../styles/text.mixins';
@import "../../styles/color";

//swiper styling
.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {


  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}



//home page styling
.home {
  @include regular-desktop {
    height: 100%;
  }
  &-start{
    @include big-desktop{
      padding-top: 128px;
    }
  }
  &-start-screen {
    display: flex;
    margin-bottom: 72px;

    @include small-desktop {
      margin-bottom: 56px;
    }

    @include tablet-width {
      flex-direction: column;
      margin-bottom: 32px;
    }

    @include mobile-width {
      margin-bottom: 24px;
    }
  }

  &-start-screen-titles {
    flex: 1;
    margin-right: 49px;

    @include tablet-width {
      margin-right: 0;
    }
  }

  &-subtitle {
    @include h2-desktop;
    margin-bottom: 24px;

    @include small-desktop{
      @include h2-desktop-small
    }

    @include tablet-width {
      @include h2-tablet;
      margin-bottom: 16px;
    }

    @include mobile-width {
      font-size: 26px;
      line-height: 36px;
      margin-bottom: 8px;
    }
  }

  &-video-wrapper {
    flex: 1;

    @include small-desktop {
      flex-basis: 570px
    }

    @include tablet-width {
      margin: 48px 0 0;
      flex-basis: auto;
    }

    @include mobile-width {
      margin: 48px 0 0;
    }
  }

  &-start-content {
    display: flex;

    @include tablet-width {
      flex-direction: column;
    }
  }

  &-start-content-text {
    @include h4-desktop;
    flex: 0 1 1056px;
    text-align: justify;

    @include small-desktop {
      @include h4-desktop-small;
    }

    @include tablet-width {
      flex-basis: auto;
      font-weight: 400;
      margin-bottom: 56px;
    }

    @include mobile-width {
      @include h5-mobile;
      margin-bottom: 46px;
    }
  }

  &-start-content-btn-wrapper {
    display: inline-flex;
    align-items: flex-end;
    justify-content: flex-end;
    padding-left: 144px;

    & > .button {
      margin-bottom: 11px;
    }

    @include small-desktop {
      & > .button {
        margin-bottom: 8px;
      }
    }

    @include tablet-width {
      flex-basis: auto;
      padding-left: 0;
      align-items: flex-start;
      justify-content: flex-start;

      & > .button {
        margin-bottom: 0;
      }
    }
  }


  //strengths Section
  &-strengths-title {
    max-width: 1020px;

    @include small-desktop {
      max-width: 686px;
    }
  }

  &-strengths-wrapper{
    @include regular-desktop {
      padding-bottom: 200px;
    }
  }

  &-strengths {
    display: grid;
    grid-template-columns: repeat(2, minmax(auto, 576px));
    justify-content: space-between;
    gap: 56px;
    margin: 72px 0 0;

    @include small-desktop {
      margin-top: 56px;
      row-gap: 40px;
      grid-template-columns: repeat(2, 436px);
    }

    @include tablet-width {
      grid-template-columns: repeat(1, minmax(auto, auto));
    }

    @include mobile-width {
      gap: 40px;
    }

  }

  &-strengths-item {

    & > h2 {
      @include h3-desktop;
      margin: 16px 0 24px;

      @include small-desktop {
        @include h3-desktop-small;
        margin: 8px 0 16px;
      }

      @include tablet-width {
        @include h3-tablet;
        margin: 16px 0 24px;
      }

      @include mobile-width {
        @include h3-mobile;
      }
    }

    & > p {
      text-align: justify;
    }

    & > img {
      @include small-desktop {
        max-width: 36px;
      }

      @include tablet-width {
        max-width: 48px;
      }

      @include mobile-width {
        max-width: 32px;
      }
    }

  }

  //model section styling
  &-model-header {
    @include tablet-width {
      display: flex;
      justify-content: space-between;
    }

    @include mobile-width {
      display: block;
    }
  }

  &-model-title {
    max-width: 636px;

    @include tablet-width {
      max-width: 576px;
    }
  }

  &-model-content {
    display: flex;
    align-items: center;
    margin-top: 20px;

    @include tablet-width {
      flex-direction: column;
      margin-top: 72px;
    }

    @include mobile-width {
      margin-top: 48px;
    }
  }

  .model-schema {
    display: flex;

    @include tablet-width {
      display: none;
    }

    & > div:first-of-type {
      display: flex;
      align-items: center;
    }

    & > div:last-of-type {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }


  .model-item {
    max-width: 468px;

    @include tablet-width {
      max-width: none;
      margin-bottom: 56px;
    }

    @include mobile-width {
      margin-bottom: 40px;
    }
  }

  .model-item.model-item--upper {
    margin-bottom: 72px;

    @include small-desktop {
      margin-bottom: 30px;
    }

    @include tablet-width {
      margin-bottom: 56px;
    }
  }

  .model-item-content {
    position: relative;
  }

  .model-item-title {
    @include h3-desktop;
    margin-left: 40px;
    position: absolute;
    left: 0;
    top: 0;
    transform: translateY(-100%);

    @include small-desktop {
      @include h3-desktop-small;
      margin-left: 30px;
    }

    @include tablet-width {
      @include h3-tablet;
      position: static;
      transform: translateY(0);
      margin: 16px 0 24px;
    }

    @include mobile-width {
      @include h3-mobile;
    }
  }

  .model-item-description {
    text-align: justify;
  }

  .model-decorator {
    &--big {
      width: 140px;
      height: 96px;
      background-position: left;
      background-size: contain;
      background-repeat: no-repeat;
      margin-bottom: 16px;

      &.services-icon { background-image: url("../../assets/homePage/home-services.svg"); }
      &.person-icon{ background-image: url("../../assets/homePage/team-person.svg"); }
      &.people-icon { background-image: url("../../assets/homePage/people.svg");}

      @include small-desktop {
        width: 105px;
        height: 72px;
      }

      @include tablet-width {
        display: none;
      }
    }

    &--small {
      display: none;

      @include tablet-width {
        display: block;
      }
    }
  }

  .model-wrapper {
     position:relative;

    @include small-desktop {
      & > img {
        width: 152px;
        height: 277.5px;
      }
    }
  }

  .model-decorator-wrapper {
    position: absolute;
    width: 142.5px;
    height: 173.5px;

    display: flex;
    align-items: center;
    justify-content: center;


    &:nth-child(1) {
      top: 0;
      right: 0;
    }

    &:nth-child(2) {
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }

    &:nth-child(3) {
      bottom: 0;
      right: 0;
    }

    @include small-desktop {
      width: 102px;
      height: 120px;
    }
  }

  .model-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: $white;
    hidden: .5;

    &-1 {
      top: 0;
      left: 0;
    }

    &-2 {
      top: 0;
      right: 0;
    }

    &-3 {
      bottom: 0;
      left: 0;
    }

    &-4 {
      bottom: 0;
      right: 0;
    }
  }

  .model-wrapper.visability-in {
    & > .model-overlay {
      transition: width .8s linear, height .8s linear;

      &-1,
      &-2{
        height: 0;
      }

      &-3,
      &-4 {
        width: 0;
      }
    }
  }

  .model-arrow {
    width: 78px;
    display: flex;
    justify-content: space-between;
    margin: 72px 24px;

    position: relative;

    &-overlay {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: $white;

      &-1 {
        left: 0;
      }

      &-2,
      &-3 {
        right: 0;
      }
    }

    &.visability-in {
      .model-arrow-overlay {
        width: 0;
        transition: width 1s ease-in-out;
      }
    }

    .model-arrow-dot {
      width: 8px;
      height: 8px;
      background-color: $black;
      border-radius: 50%;
    }

    @include small-desktop {
      width: 66px;
      margin: 66px 22px;

      .model-arrow-dot {
        width: 6px;
        height: 6px;
      }
    }
  }

  .model-wrapper.model-image--small {
    display: none;

    @include tablet-width {
      display: block;
      align-self: flex-start;
    }

    @media screen and (max-width: 650px){
      display: none;
    }
  }


  //figures section styling
  &-figures-content {
    flex: 0 1 44.12%;
    margin-right: 15px;

    @include small-desktop {
      flex-basis: 519px;
    }

    @include tablet-width {
      flex-basis: auto;
      margin-right: 0;
    }
  }

  &-figures {
    display: flex;
    justify-content: space-between;

    @include regular-desktop{
      padding: 130px 0px;
    }

    @include tablet-width {
      flex-direction: column;
    }
  }


  &-figures-text {
    margin-top: 177px;
    text-align: justify;

    @include small-desktop {
      margin-top: 140px;
    }

    @include tablet-width {
      margin-top: 48px;
      margin-bottom: 64px;
    }
  }

  &-figures-list {
    flex: 0 1 576px; //increased by margin not to be to close on smaller size
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 15px;

    @include small-desktop {
      flex: 0 1 416px;
    }

    @include tablet-width {
      flex: 0 0 0;
      margin-left: 0;
    }

  }

  &-figures-item {
    display: flex;
    align-items: center;

    @include tablet-width {
      &:not(:last-of-type) {
        margin-bottom: 56px;
      }
    }

    @include mobile-width {
      &:not(:last-of-type) {
        margin-bottom: 32px;
      }
    }
  }


  &-figures-amount {
    @include h2-desktop;
    flex: 0 0 140px;

    @include small-desktop {
      @include h2-desktop-small;
      flex-basis: 105px;
    }

    @include tablet-width {
      @include h2-tablet;
      margin-right: 32px;
    }

    @include mobile-width {
      @include h2-mobile;
      flex-basis: 80px;
      margin-right: 24px;
    }
  }

  &-figures-description {
    @include h4-desktop;

    @include small-desktop {
      @include h4-desktop-small;
    }

    @include tablet-width {
      @include h4-tablet;
    }

    @include mobile-width {
      @include h4-mobile;
    }
  }

  //customers section styling
  &-customers-title {
    max-width: 1000px;

    @include small-desktop {
      max-width: 525px;
    }

    @include tablet-width {
      max-width: none;
    }
  }

  .customer-slide {
    display: flex;
    position: relative;

    & > img {
      flex: 0 0 216px;
      height: 323px;
      object-fit: cover;
      margin-right: 24px;
      background-color: grey;

      @include just-small-desktop {
        flex: 0 0 162px;
        height: 252px;
      }

      @include smallTablet-width {
        flex: 0 0 96px;
        height: 120px;
      }
    }
  }

  .customer-slide-name {
    @include h4-desktop;

    @include small-desktop {
      @include h4-desktop-small;
    }

    @include tablet-width {
      @include h4-tablet;
    }

    @include mobile-width {
      @include h4-mobile;
    }
  }

  .customer-slide-position {
    @include h6-desktop;
    margin: 8px 0 24px;

    @include just-small-desktop {
      @include h6-desktop-small;
      margin: 6px 0 18px;
    }

    @include tablet-width {
      @include h6-tablet;
    }

    @include mobile-width {
      @include h6-mobile;
    }
  }

  .customer-slide-feedback {
    text-align: justify;
  }
  .customer-slide-feedback.feedback--desktop {
    @include smallTablet-width {
     display: none;
    }
  }

  .customer-slide-feedback.feedback--mobile {
    display: none;

    @include smallTablet-width {
      display: block;
    }
  }

  .customer-slide-decorator {
    background-image: url("../../assets/homePage/brackets.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .customer-slide-decorator.decorator--big {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-40%, -50%);
    width: 74px;
    height: 64px;
    z-index: 1;

    @include smallTablet-width {
      display: none;
    }
  }

  .customer-slide-decorator.decorator--small {
    display: none;

    @include smallTablet-width {
      display: block;
      width: 29px;
      height: 24px;
      margin-bottom: 22px;
    }

  }


  //idea block
  &-idea-title {
    max-width: 1000px;

    @include small-desktop {
      max-width: 652px;
    }

    @include tablet-width {
      max-width: none;
    }

  }

  &-idea-content {
    display: flex;
    justify-content: space-between;
    margin-top: 36px;

    @include small-desktop {
      margin-top: 24px;
    }

    @include tablet-width {
      flex-direction: column;
      max-width: 696px;
      margin-top: 48px;
    }

    @include mobile-width {
      margin-top: 32px;
    }
  }

  &-idea-text {
    max-width: 414px;
    margin: 40px 15px 0 0;
    text-align: justify;

    @include small-desktop {
      max-width: 360px;
    }

    @include tablet-width {
      margin: 0 0 64px;
      max-width: none;
    }

    @include mobile-width {
      margin: 0 0 40px;
    }
  }

  &-idea-form {
    flex-basis: 576px;

    @include small-desktop {
      flex-basis: 416px;
    }
  }
}

.home-customers{
  @include regular-desktop{
    padding: 40px 0px 80px 0px;
  }

  @include small-desktop {
    width: 100%;
  }
}
.home-idea{
  @include small-desktop {
    width: 100%;
  }
}

//slider style
.home-customers-slider {
  //for decorator
  position: relative;
  margin-top: 104px;

  @include small-desktop {
    margin-top: 96px;
  }

  @include mobile-width {
    margin-top: 54px;
  }

  .slick-slider {
    display: flex;
    flex-direction: row-reverse;

    @include tablet-width {
      flex-direction: column-reverse;
    }
  }

  //slide styling is in home block styling

  //react-slick-slider styling rewritten
  /*START HERE*/
  .slick-list {
    flex-basis: 696px;

    @include just-small-desktop {
      flex-basis: 528px;
    }

    @include tablet-width {
      max-width: 100%;
      flex-basis: auto;
    }
  }

  .slick-dots {
    @include h3-desktop;
    position: static;
    display: flex!important;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    flex: 1;
    padding: 10px 0;

    /*-ms-overflow-style: none;
    scrollbar-width: none;
*/
    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }

    @include small-desktop {
      @include h3-desktop-small;
      padding: 12px 0;
    }

    @include tablet-width {
      @include h3-tablet;
      margin-bottom: 104px;
      flex-direction: row;

      //also set this property for main.container
      overflow-y: hidden;
      //change to width: 120%; to make effect out of container
      width: 120%;
      touch-action: pan-x;
      //to go out of container from left side. margin-left === main.container padding-left
      margin-left: -44px;
    }

    @include mobile-width {
      @include h3-mobile;
      margin-bottom: 40px;
      margin-left: -16px;
      padding-right: 10px;
    }
  }

  .slick-dots li {
    width: auto;
    height: auto;
    color: $black;
    transition: color .8s;
    white-space: nowrap;

    @include tablet-width {
      margin-right: 48px;
    }

    @include mobile-width {
      margin-right: 40px;
    }
  }

  .slick-dots li.slick-active {
    color: $grey;
  }
  /*END HERE*/

  //custom slider dot styling
  .slick-dot-wrapper {
    position: relative;
  }

  .slick-dot-decorator {
    width: 48px;
    height: 2px;
    background-color: $grey;
    position: absolute;
    content: "";
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    opacity: 0;
    transition: opacity .8s;

    @include small-desktop {
      width: 36px;
    }

    @include tablet-width {
      position: static;
      transform: translateY(0);
    }
  }

  li.slick-active .slick-dot-decorator {
    opacity: 1;
  }

  .slick-dot-title {
    margin-left: 0;
    transition: margin-left .8s;
  }

  li.slick-active .slick-dot-title {
    margin-left: 72px;

    @include small-desktop {
      margin-left: 54px;
    }

    @include tablet-width {
      margin-left: 0;
    }
  }
}
