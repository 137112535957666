@import "devices.mixins";

.svg {
  width: 48px;
  height: 48px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  @include small-desktop {
    width: 32px;
    height: 32px;
  }

  @include tablet-width {
    width: 48px;
    height: 48px;
  }

  @include mobile-width {
    width: 32px;
    height: 32px;
  }

  //home-model
  //big ones
  &.services-icon { background-image: url("../assets/homePage/services-icon.svg"); }
  &.person-icon{ background-image: url("../assets/homePage/person-icon.svg"); }
  &.people-icon { background-image: url("../assets/homePage/fame.svg");}
  //small ones
  &.team-small { background-image: url("../assets/homePage/team-small.svg");}


  //genesys-services
  &.config { background-image: url("../assets/genesys/config.svg");}
  &.interactions { background-image: url("../assets/genesys/interactions.svg");}
  &.tools { background-image: url("../assets/genesys/tools.svg");}
  &.workforce { background-image: url("../assets/genesys/workforce.svg");}

  //genesys-solution
  &.head { background-image: url("../assets/genesys/head.svg");}
  &.interaction { background-image: url("../assets/genesys/interaction.svg");}
  &.note { background-image: url("../assets/genesys/note.svg");}

  //for services-block
  &.artificial-intelligence { background-image: url("../assets/svgServices/artificial-intelligence.svg");}
  &.bug { background-image: url("../assets/svgServices/bug.svg");}
  &.case { background-image: url("../assets/svgServices/case.svg");}
  &.chart { background-image: url("../assets/svgServices/chart.svg");}
  &.person { background-image: url("../assets/svgServices/person.svg");}
  &.cookie { background-image: url("../assets/svgServices/cookie.svg");}
  &.cloud-infrastructure { background-image: url("../assets/svgServices/cloud-infrastructure.svg");}
  &.TV {background-image: url("../assets/svgServices/TV.svg"); }
  &.settings { background-image: url("../assets/svgServices/settings.svg");}
  &.list-svg { background-image: url("../assets/svgServices/list.svg");}
  &.maintenance { background-image: url("../assets/svgServices/maintenance.svg");}
  &.mobile-web { background-image: url("../assets/svgServices/mobDev.svg");}
  &.rocket { background-image: url("../assets/svgServices/rocket.svg");}
  &.system-integration { background-image: url("../assets/svgServices/system-integration.svg");}
  &.web-app { background-image: url("../assets/svgServices/webAppDev.svg");}
  &.third-party { background-image: url("../assets/svgServices/3d-party.svg");}


}