@import "../../styles/devices.mixins";
@import "../../styles/color";
@import "../../styles/text.mixins";

.menu-item {
  @include h6-desktop;
  cursor: pointer;

  @include small-desktop {
    @include h6-desktop-small;
  }

  @include tablet-width {
    @include h2-desktop;
    margin: 32px 0;
  }

  @include mobile-width {
    @include h3-desktop;
    margin: 15px 0;
  }

  &:hover a {
    color: $grey;
  }
}
