@import "../../styles/main";

.footer-item {
  cursor: pointer;
  height: 26px;
  overflow-y: hidden;

  &:not(:last-of-type) {
    margin-right: 20px;
  }

  .footer-slider {
    transform: translateY(0%);
    transition: transform 500ms;

    & > p {
      padding: 5px 0;
    }
  }

  &:hover .footer-slider {
    transform: translateY(-50%);
  }
}
